<template>
  <v-app id="inspire">

    <v-content>

      <v-container
        class="fill-height"
        fluid
      >

        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >

            <div class="text-center pa-8 pb-4">
                <img :style="'width:'+app.state.style.logoWidth+';'" :src="app.state.style.logo">
            </div>

            <v-card outlined v-if="!lostpassword">

              <v-toolbar outlined flat>

                <v-spacer />
                <v-toolbar-title>{{ $t('Login') }}</v-toolbar-title>
                <v-spacer />
             
              </v-toolbar>

              <v-divider></v-divider>

              <v-card-text class="pb-0 pt-8">
                <v-form>
                  <v-text-field
                    label="E-Mail"
                    name="login"
                    type="text"
                    v-model="email"
                    :rules="[rules.required]"
                    outlined
                  />

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    v-model="password"
                    :rules="[rules.required]"
                    outlined
                    v-on:keyup.enter="submit();"
                  />
                </v-form>
              </v-card-text>

              <v-divider class="pb-4"></v-divider>

              <v-card-text class="pa-0 pl-2">

                <v-btn :color="app.state.style.primary" :loading="loading" depressed class="mx-2" @click="login();">{{ $t('Login') }}</v-btn>
                <v-btn :color="app.state.style.primary" depressed class="mx-2" text @click="toggleLostPassword">{{ $t('Lost Password') }}</v-btn>
                
                <v-spacer />

              </v-card-text>

              <v-divider class="mt-4" v-if="error"></v-divider>

              <v-alert class="mx-4 mt-4" outlined type="error" v-if="error">
                {{error}}
              </v-alert>

              <v-alert class="mx-4 mt-4" outlined type="success" v-if="success">
                {{success}}
              </v-alert>
              
              <v-divider class="mt-4"></v-divider>

              <div class="pa-4">

                <v-btn
                  text
                  @click="providerLogin('google');"
                  outlined color="grey"
                  block
                > 
                
                {{ $t('Google login') }}

                </v-btn>

              </div>

              <!-- <v-divider class="mt-4"></v-divider>

              <v-btn
                text
                class="ma-4 mr-0"
                outlined color="grey"
                to="/"
              > 
                
                Home

              </v-btn> -->

            </v-card>

            <v-card outlined v-if="lostpassword">

              <v-toolbar
                outlined
                flat
              >
                <v-toolbar-title>{{ $t('Recover Password') }}</v-toolbar-title>
                <v-spacer />
             
              </v-toolbar>

              <v-divider />

              <v-card-text class="pt-8 pb-0">

                <v-form>
                  <v-text-field
                    label="E-Mail"
                    name="login"
                    type="text"
                    v-model="email"
                    :rules="[rules.required]"
                    outlined
                  />

                </v-form>

              </v-card-text>

              <v-divider />

              <v-card-actions class="pa-0 pb-4 pl-2 pt-4">

                <v-btn :loading="loading" depressed class="mx-2" color="primary" @click="SendLostPassword();">{{ $t("Recover password") }}</v-btn>
                <v-btn outlined class="mx-2" color="grey" @click="toggleLostPassword();">{{ $t("Cancel") }}</v-btn>
                
                <v-spacer />

              </v-card-actions>

              <v-divider class="mt-0" v-if="error || success"></v-divider>

              <div class="pa-4" v-if="error || success">
                  
                <v-alert class="ma-0" outlined type="error" v-if="error">
                  {{error}}
                </v-alert>

                <v-alert class="ma-0" outlined type="success" v-if="success">
                  {{success}}
                </v-alert>

              </div>

            </v-card>

          </v-col>
        </v-row>
      </v-container>

    </v-content>
  </v-app>
</template>

<script>
  export default {
    
  }
</script>

<script>

import Header from '../components/Header';
import firebase from "firebase";
import { inject, ref } from '@vue/composition-api' ;

export default {
    props: {
        source: String,
    },
    components: {
      Header,
    },
    setup(props, ctx){

      const app = inject('app') ;
      const currentUser = inject('currentUser') ;

      const email = ref('') ;
      const password = ref('') ;
      const lostpassword = ref(false) ;
      const loading = ref(false) ;

      const error = ref(null) ;
      const success = ref(null) ;

      const rules = {
        required: value => !!value || ctx.root.$t('Required.'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || ctx.root.$t('Invalid e-mail.')
        },
      }

      const toggleLostPassword = () => {

        if(lostpassword.value){
          lostpassword.value = false ;
        }else{
          lostpassword.value = true ;
        }

      }

      const login = () => {

        loading.value = true ;

        currentUser.login(email.value, password.value)
        .then(data => {

          if(app.state.redirect){
            ctx.root.$router.push(app.state.redirect);
          }else{
            ctx.root.$router.push('/websites');
          }
          
        })
        .catch(() => {

          currentUser.checkPassword(email.value, password.value)
            .then((response) => {

              // create user in 
              if(response.data == 'ok'){
                
                // try to login this user again
                firebase
                  .auth()
                  .signInWithEmailAndPassword(email.value, password.value)
                  .then(user => {

                    if(app.state.redirect){
                      ctx.root.$router.push(app.state.redirect);
                    }else{
                      ctx.root.$router.push('/websites');
                    }
                    
                  }).catch((err) => {
                
                    loading.value = false ;
                    app.setError(err.message) ;
                    
                  });

              }else{
                
                if(response.data){
                  app.setError(response.data.message) ;
                }else{
                  app.setError('Unknown error') ;
                } 
                
                loading.value = false ;

              }
              
            }).catch((err) => {

              loading.value = false ;
              app.setError(err.message) ;
              
            });

        });

      }

      const providerLogin = (providerName) => {

        loading.value = true ;

        if(providerName == 'google'){
          var provider = new firebase.auth.GoogleAuthProvider();
        }

        firebase.auth().signInWithPopup(provider)
        .then((result) => {

          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;

          if(app.state.redirect){
            ctx.root.$router.push(app.state.redirect);
          }else{
            ctx.root.$router.push('/websites');
          }

        }).catch((error) => {

          app.setError(error.message+" ("+error.code+")") ;

          loading.value = false ;


        });

      }

      const SendLostPassword = () => {

        loading.value = true ;
        success.value = null ;

        if(!email.value){
          error.value = ctx.root.$t("Please enter an e-mail address") ; 
        };

        firebase.auth().sendPasswordResetEmail(email.value).then(() => {
          error.value = null ;
          success.value = ctx.root.$t("Please check your e-mail to recover your password") ; 
          loading.value = false ;
        }).catch(err => {
          error.value = err.message;
          loading.value = false ;
          success.value = null ;
        });

      }

      return {
        currentUser,
        email,
        password,
        lostpassword,
        toggleLostPassword,
        SendLostPassword,
        loading,
        rules,
        login,
        providerLogin,
        error,
        success,
        app
      }
      
    }
};

</script>
